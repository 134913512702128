import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="text-picker"
//
// Use the text picker to connect a select field with a text field.
// example:
// <div data-controller="text-picker">
//   <input data-text-picker-target="output" type="text" value="Option 1">
//   <select data-action="change->text-picker#updateText">
//     <option value="1">Option 1</option>
//     <option value="2">Option 2</option>
//   </select>
// </div>
export default class extends Controller {

  static targets = [ "output" ];

  connect() {
  }

  disconnect() {
  }

  // This method is called when the select field changes
  updateText(event) {
    this._updateOutputTarget(this._getSelectedOptionText(event));
  }

  _getSelectedOptionText(event) {
    const selectedOption = event.target.options[event.target.selectedIndex];
    return selectedOption.value ? selectedOption.text : '';
  }

  _updateOutputTarget(newText) {
    const separator = this.outputTarget.value.length === 0 ? '' : '\n';
    this.outputTarget.value += `${separator}${newText}`;
  }
}
