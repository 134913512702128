import { Controller } from "@hotwired/stimulus"
import Modal from "bootstrap/js/dist/modal"

// Connects to data-controller="bs-modal-component"
// Allows you to open a Bootstrap modal from Stimulus e.g. when responding to a turbo_stream where JavaScript is not executed
export default class extends Controller {
  static values = {
    keepOpen: { type: Boolean, default: false }
  }

  connect() {
    this.modal = new Modal(this.element)
    this.form = this.element.querySelector('form')

    // prevent modal close on form submit e.g. when using a turbo_stream
    if (this.form && !this.keepOpenValue) {
      this.form.addEventListener('submit', () => {
        this.modal.hide()
      })
    }

    this.modal.show()
  }

  close() {
    this.modal.hide()
    // remove the modal from the DOM to not show it again when navigating back
    this.element.remove()
  }

  disconnect() {
    // remove the modal from the DOM to not show it again when navigating back
    this.element.remove()
  }
}
