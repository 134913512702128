import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="datatable"

export default class extends Controller {

  static values = {
    url: String,
    serverSide: { type: Boolean, default: false },
    columns: Array
  }

  static targets = ['table', 'filter']

  connect() {
    this._initializeDataTable();
    this._addDetailsListener();
  }

  disconnect() {
    if (this.dataTable) {
      this.dataTable.destroy();
    }
  }

  _initializeDataTable() {
    let setup = {
      "retrieve": true, // this option deals with the problem that the datatable is not initialized again after a turbo visit
      "lengthMenu": [[25, 50, 100, 10000], [25, 50, 100, 'Alle']],
      "select": {
        "style": 'os',
        "selector": 'tr>td:not(.disable-select)'
      },
      "stateSave": false,
      "searching": false,
      "ordering": true,
      "processing": true,
      "serverSide": this.serverSideValue,
      "dom": '<"center"t><"d-flex justify-content-end align-items-baseline gap-4" <"me-auto"p>il>',
    }

    if (this.hasUrlValue) {
      setup.ajax = {
        "url": this.urlValue,
        "type": "GET",
        "data": this.beforeSendData.bind(this)
      };
      console.log('datatable setup', setup);
    }

    if (this.hasColumnsValue) {
      setup.columns = this.columnsValue;
    }

    if (this.hasTableTarget) {
      this.dataTable = $(this.tableTarget).DataTable(setup);
    }
  }

  _addDetailsListener() {
    if (!this.hasTableTarget) return;

    $(this.tableTarget).on('click', 'td.details-control', (event) => {
      let tr = $(event.target).closest('tr');
      let row = this.dataTable.row(tr);

      if (row.child.isShown()) {
        row.child.hide();
        tr.removeClass('shown');
      } else {
        // Open this row
        $.ajax({
          url: row.data().links.details.href
        }).done(function( data ) {
          row.child( data ).show();
        });
        tr.addClass('shown');
      }
    });
  }

  beforeSendData(data) {
    this.filterValues.forEach((filter) => {
      data[filter.name] = filter.value;
    });
  }

  reload() {
    this.dataTable.ajax.reload();
  }

  filterChanged() {
    if (this.hasFilterTarget === true) {
      this.reload();
    }
  }

  get filterValues() {
    return this.filterTargets.map((filter) => {
      return {
        name: filter.dataset.filterName,
        value: filter.value
      }
    });
  }
}
