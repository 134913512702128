import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="loader-overlay"
// attach data-controller="loader-overlay" to any form you know it's slow in responding - it will show a loader overlay
export default class extends Controller {
  connect() {
    this.element.addEventListener('submit', this.showLoader.bind(this))
  }

  showLoader() {
    get('/admin/loader_overlay', {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    }).then(response => {
      if (response.ok) {
        // request.js handles this
      } else {
        console.log(response)
      }
    })
  }
}
