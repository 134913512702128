import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="text-length-indicator"
export default class extends Controller {

  static targets = ["input", "indicator"];
  static values = { maxLength: Number };

  connect() {
    this.updateIndicator();
  }

  updateIndicator() {
    const commentIsLongEnough = this.inputTarget.value.trim().length >= this.maxLengthValue;
    const indicatorContent = commentIsLongEnough
      ? '<span class="text-primary"><i class="fa fa-regular fa-circle-check" aria-hidden="true"></i></span>'
      : '<span class="text-danger"><i class="fa fa-regular fa-circle-xmark" aria-hidden="true"></i></span>';
    this.indicatorTarget.innerHTML = indicatorContent;
  }
}
