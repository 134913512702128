import { Controller } from "@hotwired/stimulus"
import {useMutation} from "stimulus-use";

// Connects to data-controller="document-selection-mutation"
// handle childList update on a dependent tom-select
// connect this controller to a tom select to make tom select update
// according to the options added or removed from the original select
export default class extends Controller {
  connect() {
    useMutation(this, { childList: true })
  }

  mutate(entries) {
    for (const mutation of entries) {
      if (mutation.type === 'childList') {
        mutation.removedNodes.forEach((removedNode) => {
          this.element.tomselect.removeOption(removedNode.value);
        })

        mutation.addedNodes.forEach((addedNode) => {
          this.element.tomselect.addOption({
            value: addedNode.value,
            text: addedNode.text
          });
        })
      }
    }

    this.element.tomselect.enable()
    this.element.tomselect.unlock()
  }
}
