import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="collection-document"
export default class extends Controller {
  static values = { url: String }
  connect() {
    if (!this.hasUrlValue) return

    get(this.urlValue, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      }
    }).then(response => {
      if (response.ok) {
        // request.js handles this
      } else {
        console.log(response)
      }
    })
  }
}
