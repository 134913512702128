import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="score-business-partner-select"
export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    let select = document.getElementById('business_partner_id');
    let control = select.tomselect;

    // This is done as anonymous function as a function on class level will have problems accessing
    // the instance variables as stimulus is disconnected when selecting an item in TomSelect.
    control.on('item_add', (value, item) => {
      if(!value) return {}
      let url = `${this.urlValue}?id=${value}`
      let formContainer = document.querySelector('#form-container')
      let submitButton = document.getElementById('score_form_submit')

      fetch(url)
        .then((response) => response.text())
        .then((text) =>{
          formContainer.innerHTML = text;
          // We need to set the for attribut for the submit button as it is no in the form scope if the form is
          // loaded after the modal show
          let formId = formContainer.querySelector('form').getAttribute('id')
          submitButton.setAttribute('form', formId)
        })
    });
  }
}
