import { Controller } from "@hotwired/stimulus"
import jquery from "jquery"
window.$ = jquery
window.jQuery = jquery

// The controller will call: window.jQuery(table).DataTable(config)
require('datatables.net')

// These examples use bootstrap4 and the scroller plugin.
// See https://datatables.net/download for more options.
require('datatables.net-bs5')
require('datatables.net-scroller-bs5')

// Connects to data-controller="orders-data-table"
export default class extends Controller {
  connect() {
    let urls = $('div.urls');
    let searchParams = new URLSearchParams(window.location.search);
    const queryString = '?start_date=' + (searchParams.get('start_date') || '') +
      '&end_date=' + (searchParams.get('end_date') || '') +
      '&state[]=' + (searchParams.get('state[]') || []) +
      '&due[]=' + (searchParams.get('due[]') || []) +
      '&factoring_type[]=' + (searchParams.get('factoring_type[]') || []) +
      '&business_partner=' + (searchParams.get('business_partner') || '') +
      '&sortabl=' + (searchParams.get('sortabl') || '') +
      '&query=' + (searchParams.get('query') || '')

    const ordersTable = $('#orders').DataTable({
      "language": {
        "decimal": ",",
        "thousands": "."
      },
      "order": [[1, "desc"]],
      "columnDefs": [
        {"name": "order_external_id", "targets": 0, "orderable": true},
        {"name": "order_created_at", "targets": 1, "orderable": true},
        {"name": "order_state", "targets": 2, "orderable": true},
        {"name": "from_name", "targets": 3, "orderable": true},
        {"name": "to_name", "targets": 4, "orderable": true},
        {"name": "invoice_number", "targets": 5, "orderable": true},
        {"name": "subject", "targets": 6, "orderable": false},
        {"name": "invoice_date", "targets": 7, "orderable": true},
        {"name": "target", "targets": 8, "orderable": true},
        {"name": "amount", "targets": 9, "orderable": false},
        {"name": "order_factoring_type", "targets": 10, "orderable": true},
        {"name": "order_type", "targets": 10, "orderable": true}
      ],
      "lengthMenu": [[50, 200, -1], [50, 200, "All"]],
      "select": {
        "style": 'os',
        "selector": 'tr>td:not(.disable-select)'
      },
      "stateSave": true,
      "searching": false,
      "ordering": true,
      "processing": true,
      "serverSide": true,
      "ajax": urls.data('orders-url') + queryString,
      "deferRender": true,
      "columns": [
        {
          "data": "external_id",
          "class": "nowrap"
        },
        {"data": "created_at"},
        {"data": "state"},
        {"data": "creditor"},
        {"data": "debitor"},
        {
          "data": "number",
          "class": "nowrap"
        },
        {"data": "subject"},
        {"data": "voucher_date"},
        {
          "data": "target",
          "class": "nowrap"
        },
        {"data": "amount"},
        {"data": "factoring_type"},
        {"data": "type"}
      ],
      "dom": '<"center"t><"d-flex justify-content-end align-items-baseline gap-4" <"me-auto"p>il>',
    });

    $('#orders tbody').on('click', 'td.details-control', function () {
      var tr = $(this).closest('tr');
      var row = ordersTable.row(tr);
      var order_id = row.id();
      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass('shown');
      } else {
        // Open this row
        $.ajax({
          url: urls.data('sub-orders-url') + '?id=' + order_id,
        }).done(function (data) {
          row.child(data).show();
        });
        tr.addClass('shown');
      }
    });
  }}
