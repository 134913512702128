import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="business-partner-creation"
export default class extends Controller {
  connect() {
    this.initBusinessPartnerSearch();
  }

  initBusinessPartnerSearch() {
    const bpSearchResultsContainer = document.querySelector('.bp-search-results-container');
    const searchField = document.querySelector('.bp-search-input');
    // Nothing found - empty form
    const openBpFormButtonNew = document.querySelector('#open-bp-form-btn-new');
    // Create new BP out of selection - prefilled form
    const openBpFormButtonData = document.querySelector('#open-bp-form-btn-data');
    const focusResult = document.querySelector(".focus-result");

    bpSearchResultsContainer.addEventListener('click', event => handleSelect(event));
    searchField.addEventListener('keyup', debounce(fetchAndRender));

    async function fetchAndRender() {
      try {
        if ( searchField.value.length < 3 )
          return;

        makeVisible(bpSearchResultsContainer);
        showLoading();

        let searchParams = new URLSearchParams();
        searchParams.append('term', searchField.value);

        const response = await fetch(`/admin/partners/find-global?${searchParams.toString()}`);
        const results = await response.json();
        bpSearchResultsContainer.innerHTML = "";

        let addressesHTML = results.map(r => buildHTMLresult(r));
        bpSearchResultsContainer.innerHTML = addressesHTML.join("");
        makeVisible(document.querySelector('.confirm-button-box'));
      } catch {
        console.log("Could not fetch data.");
      }
    }

    function buildHTMLresult(r) {
      const addressHTML = `
              <div class="col-3 mb-2 cursor-pointer">
                <div class="card bp-result ${r.class}" data-id="${r.id}" data-source="${r.source}">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="bp-result-name fw-bold">${r.company}</div>
                      <div class="bp-result-line text-muted">${r.line1} ${r.line2 || ""}</div>
                      <div class="bp-result-line text-muted">${r.zip} ${r.city}</div>
                      <div class="bp-result-line text-muted">${r.country}</div>
                      <div class="bp-result-source float-end">${r.source}</div>
                    </div>
                  </div>
                </div>
              </div>`;
      return addressHTML;
    }

    async function handleSelect(event) {
      try {
        const element = event.target.closest(".bp-result");
        if (!element) return;
        makeVisible(focusResult);
        const response = await fetch(`/admin/partners/find-global/details?id=${element.dataset.id}&source=${element.dataset.source}`);
        const r = await response.json();
        const partner_link = r.source === 'infact' ? `
              <strong>
                <a href="/admin/partners/${r.external_id}" target="_blank">
                  Externe ID: ${r.external_id}
                </a>
              </strong>` : ""
        const focusAddressHTML = `
              <div class="card">
                <div class="card-content">
                   <div class="card-body">
                    <div class="card-title h4 focus-result-title">${r.company}</div>
                    <div class="focus-result-infos row">
                      <div class="focus-result-infos--left col-2">
                        <p>${r.line1}</br>
                        ${r.line2 === (null || undefined) ? '' : (r.line2 + "</br>")}
                        ${r.zip} ${r.city}</br>
                        ${r.country === null ? '' : r.country}</p>
                      </div>
                      <dl class="focus-result-infos--right col-10 row">
                        <dt class="col-3">Quelle</dt>
                        <dd class="col-9">${r.source}</dd>
                        <dt class="col-3">Rechtsform</dt>
                        <dd class="col-9">${r.legal_form}</dt>
                        <dt class="col-3">Registernummer</dt>
                        <dd class="col-9">${ r.registry_number ? r.registry_number : "k.A."}</dt>
                        <dt class="col-3">Erstellt</dt>
                        <dd class="col-9">${r.created ? r.created : "k.A."}</dt>
                        <dt class="col-3">Geändert</dt>
                        <dd class="col-9">${r.updated ? r.updated: "k.A."}</dt>
                        <dt class="col-3"></dt>
                        <dd class="col-9">${partner_link}</dl>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            `
        let searchParams = new URLSearchParams(r);
        let getUrl = "/admin/partners/new/?" + searchParams;
        createNewTriggerButton(getUrl);

        focusResult.innerHTML = focusAddressHTML;
        makeVisible(openBpFormButtonData);
      } catch {
        console.log("Could not fetch data.")
      }
    }

    function makeVisible(element) {
      if (!element.classList.contains("d-none")) return;
      element.classList.remove("d-none");
    }

    function showLoading() {
      bpSearchResultsContainer.innerHTML = `<div class="d-flex justify-content-center"><div class="spinner-border text-primary" role="status"><div class="visually-hidden">Lädt...</div></div></div>`
    }

    function createNewTriggerButton(getUrl) {
      document.getElementById("open-bp-form-btn-data").setAttribute('href', getUrl)
    }

    function debounce(func, timeout = 1500){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      }
    }
  }
}
