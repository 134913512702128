import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="utility"
export default class extends Controller {
  connect() {}

  // resets the form that contains the event target and submits the form
  resetForm(event) {
    let form = event.target.closest('form')
    form.reset()
    form.querySelectorAll('select').forEach((select) => {
      select.selectedIndex = 0
    })
    form.submit()
  }
}
