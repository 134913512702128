import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="upload-preview"
export default class extends Controller {
  static targets = [ "input", "preview" ]
  connect() {
    if ( !this.hasInputTarget ) {
      console.log('no input target defined')
      return
    }

    if ( !this.hasPreviewTarget ) {
      console.log('no preview target defined')
      return
    }

    this.inputTarget.addEventListener("change", this.previewFiles.bind(this))
  }

  // builds a preview of the file(s) to be uploaded as embedded objects utilizing the browser's native preview functionality
  previewFiles() {
    console.log('previewFiles')
    this.previewTarget.innerHTML = ""
    Array.from(this.inputTarget.files).forEach(file => {
      const embeddedFile = document.createElement("embed")
      embeddedFile.src = URL.createObjectURL(file)
      embeddedFile.width = 800
      embeddedFile.height = 600
      this.previewTarget.appendChild(embeddedFile)
    })
  }
}
