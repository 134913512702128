import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

// Connects to data-controller="fake-submit-button"
export default class extends Controller {
  static targets = ['fakeButton', 'fakeSaveAllButtonWrapper', 'fakeSaveAllButton']
  connect() {}

  fakeSaveAllButtonTargetConnected(target) {
    target.addEventListener('click', (event) => {
      this.formSubmit()
    })
  }

  fakeButtonTargetConnected(target) {
    target.closest('form').addEventListener('input', (event) => {
      event.target.closest('form').dataset.changed = 'true'
      this.fakeSaveAllButtonWrapperTarget.classList.remove('d-none')
    })
  }
  formSubmit() {
    console.log('formSubmit')
    // submit all forms with the class 'submit-by-fake-button' to imitate a "save all" button in the upper right of the page
    // each form itself must implement the logic to save all its fields and respond to the request via turbo_stream
    this.fakeButtonTargets.forEach((submit) => {
      let form = submit.closest('form')
      let formData = new FormData(form)
      let inputs = form.querySelectorAll('input')

      // only submit form when changes are detected
      if (form.dataset.changed !== 'true') {
        console.log('no changes detected skipping form to action' + form.action)
        return
      }

      // each checkbox comes with a hidden field with the same name and a value of 0 to ensure the value of a non checked
      // checkbox is submitted as 0.
      // To NOT overwrite the value 0 with the value 1 of an unchecked checkbox, we only add the value of the checkbox
      // to the form data when it is checked
      inputs.forEach((input) => {
        if (input.type === 'checkbox') {
          if (input.checked) {
            formData.append(input.name, input.value)
          }
        } else {
          formData.append(input.name, input.value)
        }

      })
      post(form.action, {
        body: formData,
        headers: {
          'Accept': 'text/vnd.turbo-stream.html'
        }
      }).then(response => {
        if (response.ok) {
          // the form was submitted and the response was successful - add code if you need to do something here
          form.dataset.changed = 'false'
          this.fakeSaveAllButtonWrapperTarget.classList.add('d-none')
        } else {
          console.log('form submission failed')
          console.dir(response)
        }
      })
    })
  }
}
