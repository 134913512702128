import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="clearing"
export default class extends Controller {
  static targets = ["revertClearing"]
  static values = {revertClearingUrl: String}

  connect() {
    this.bookingJournalData = {};
    this.isProcessingCanceled = false;
    this.lastPercentage = 0;
  }

  revertClearing() {
    this.revertClearingTarget.disabled = true;

    let spinner = document.createElement('span');
    spinner.classList.add('spinner-border', 'spinner-border-sm');
    this.revertClearingTarget.appendChild(spinner);

    $.ajax({
      url: this.revertClearingTarget.dataset.revertClearingUrlValue,
      method: 'POST',
      data: {partner_ids: this.collectPartnerIds()},
      dataType: 'json',
      success: function (response) {
        location.reload();
      },
      error: function () {
        $('#revert_clearing').prop('disabled', false)
      }
    });
  }

  collectPartnerIds() {
    let partnerIds = [];
    document.querySelectorAll('tr.selected').forEach((row) => {
      partnerIds.push(row.id);
    });
    return partnerIds;
  }

}
