import {Controller} from "@hotwired/stimulus"
import Tooltip from "bootstrap/js/dist/tooltip"

// https://getbootstrap.com/docs/5.2/components/tooltips/
// Connects to data-controller="tooltip"
export default class extends Controller {
  connect() {
    // don't try to render a tooltip if there is no title
    if (this.element.dataset.bsTitle) {
      this.tooltip = new Tooltip(this.element)
    }
  }
}
