import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bounded-input"
export default class extends Controller {
  static targets = ["input"];
  static values = {
    min: { type: Number, default: 0 },
    max: Number,
  };

  connect() {
    this.limitInput();
  }

  handleChange() {
    this.limitInput();
  }

  get input() {
    return parseFloat(this.inputTarget.value);
  }

  limitInput() {
    if (!this.hasInputTarget) return;
    if (isNaN(this.input)) return;
    // set value to min
    if (this.hasMinValue && this.input < this.minValue) {
      this.inputTarget.value = this.minValue;
    }
    // set value to max
    if (this.hasMaxValue && this.input > this.maxValue) {
      this.inputTarget.value = this.maxValue;
    }
  }
}
