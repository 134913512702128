import { Controller } from "@hotwired/stimulus"
import { Flatpickr } from 'flatpickr/dist/flatpickr';
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'
import 'flatpickr/dist/l10n/de.js'


// Connects to data-controller="date-range-picker"
export default class extends Controller {
  static values = {
    minDate: { type: String, default: '1900-01-01' },
    maxDate: { type: String, default: '2100-01-01' },
    secondInputId: String,
    showIntervals: { type: Boolean, default: true },
    position: { type: String, default: 'below auto' }
  }

  static targets = [ "startDate", "endDate", 'toggle' ];

  connect() {
    const controllerInstance = this;

    this.strftime = require('strftime')
    let aDay = 24 * 60 * 60 * 1000;
    let today = new Date();
    let currentMonth = today.getMonth();
    let currentYear = today.getFullYear();

    let yesterday = new Date(today - aDay);
    let sevenDaysAgo = new Date(today - 6 * aDay);
    let thirtyDaysAgo = new Date(today - 29 * aDay);

    // clicking the link inside a form somehow submits the form which we don't want
    this.toggleTarget.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      return false;
    });

    this.pickr = this.element.flatpickr({
      "locale": "de",
      mode: 'range',
      enableTime: false,
      dateFormat: "d.m.Y", // "Y-m-d",
      showMonths: 2,
      minDate: this.minDate,
      maxDate: this.maxDate,
      weekNumbers: true,
      position: this.positionValue,
      defaultDate: this.initialDateRange,
        ...this.buttonPlugin(this.showIntervalsValue, aDay, today, currentMonth, currentYear, yesterday, sevenDaysAgo, thirtyDaysAgo),
      onClose: (selectedDates, dateStr, instance) => {
        this.dateRange = selectedDates;
        this.displayText = dateStr;
      },
      onChange: (selectedDates, dateStr, instance) => {
        // remove active class from all buttons when a date is selected manually
        instance.pluginElements.forEach((element) => {
          element.classList.remove('active');
        })

        this.dateRange = selectedDates;
        this.displayText = dateStr;
      },
      onReady: (selectedDates, dateStr, instance) => {
        this.displayText = dateStr;
      }
    })
    // we need to take a detour via pickr instance to get to the correct shortcut buttons plugin wrapper when there are multiple
    // date pickers on the same page
    // pluginElements is an array of all plugin elements, we only use shortcut buttons plugin, so we take the first element
    // and then go up the DOM tree to get to the wrapper
    // DOM structure:
    // .shortcut-buttons-flatpickr-wrapper    <- this.pickr.pluginElements[0].parentElement.parentElement
    //   .shortcut-buttons-flatpickr-buttons  <- this.pickr.pluginElements[0].parentElement
    //     .shortcut-buttons-flatpickr-button <- this.pickr.pluginElements[0]
    //     .shortcut-buttons-flatpickr-button
    //     ...
    this.shortcutButtonWrapper = this.pickr.pluginElements[0].parentElement.parentElement
    this.adjustShortcutButtonPosition()
  }

  buttonPlugin(enabled, aDay, today, currentMonth, currentYear, yesterday, sevenDaysAgo, thirtyDaysAgo) {
    const controllerInstance = this;
    if (enabled) {
      return {
        plugins: [
          new ShortcutButtonsPlugin({
            button: [
              { label: 'Heute' },
              { label: 'Gestern' },
              { label: 'Letzten 7 Tage' },
              { label: 'Letzten 30 Tage' },
              { label: 'Dieser Monat' },
              { label: 'Letzter Monat' },
              { label: 'Diese Jahr' },
              { label: 'Letztes Jahr' },
              { label: 'Gesamter Zeitraum' }
            ],
            onClick(index, fp) {
              let date
              switch(index) {
                case 0: // heute
                  date = [today, today];
                  break;
                case 1: // gestern
                  date = [yesterday, yesterday];
                  break;
                case 2: // letzten 7 Tage
                  date = [sevenDaysAgo, today];
                  break;
                case 3: // letzten 30 Tage
                  date = [thirtyDaysAgo, today];
                  break;
                case 4: // dieser Monat
                  date = [new Date(currentYear, currentMonth, 1), new Date(currentYear, currentMonth + 1, 0)];
                  break;
                case 5: // letzter Monat
                  date = [new Date(currentYear, currentMonth - 1, 1), new Date(currentYear, currentMonth, 0)];
                  break;
                case 6: // dieses Jahr
                  date = [new Date(currentYear, 0, 1), new Date(currentYear, 11, 31)];
                  break;
                case 7: // letztes Jahr
                  date = [new Date(currentYear - 1, 0, 1), new Date(currentYear - 1, 11, 31)];
                  break;
                case 8: // gesamter Zeitraum
                  // "this" is not the controller instance here, so we have to use the controllerInstance
                  date = [controllerInstance.minDate, controllerInstance.maxDate];
                  break;
              }

              // remove active class from all buttons when a button is clicked
              fp.pluginElements.forEach((element) => {
                element.classList.remove('active');
              })

              // set active class on clicked button
              fp.pluginElements[index].classList.add('active');
              fp.setDate(date);
              fp.close();
            }
          })
        ]
      }
    } else {
      return {
        plugins: []
      }
    }
  }

  adjustShortcutButtonPosition() {
    if (!this.shortcutButtonWrapper) return;

    if (this.positionValue.includes('right')) {
      // when aligning to the right, we need to add the shortcut buttons on the left since the space on the right is limited
      this.shortcutButtonWrapper.classList.add('sbf--position-left');
    } else if (this.positionValue.includes('left')) {
      // when aligning to the left, we need to add the shortcut buttons on the right since the space on the left is limited
      this.shortcutButtonWrapper.classList.add('sbf--position-right');
    }
  }

  get startDate() {
    return Date.parse(this.startDateTarget.value);
  }

  get endDate() {
    return Date.parse(this.endDateTarget.value);
  }

  get minDate() {
    return Date.parse(this.minDateValue);
  }

  get maxDate() {
    return Date.parse(this.maxDateValue);
  }

  get initialDateRange()  {
    if (this.hasStartDateTarget && this.hasEndDateTarget) {
      return [this.startDate, this.endDate];
    }
    return this.defaultDateRange;
  }

  get defaultDateRange() {
    let oneDay = 24 * 60 * 60 * 1000;
    let today = new Date();
    let thirtyDaysAgo = new Date(today - 29 * oneDay);

    return [thirtyDaysAgo, today];
  }

  set dateRange(selectedDates) {
    // update hidden input fields
    if (this.hasStartDateTarget && selectedDates[0]) {
      this.startDateTarget.value = this.strftime('%Y-%m-%d', selectedDates[0]);
      this.startDateTarget.dispatchEvent(new Event('change'));
    }
    if (this.hasEndDateTarget && selectedDates[1]) {
      this.endDateTarget.value = this.strftime('%Y-%m-%d', selectedDates[1]);
      this.endDateTarget.dispatchEvent(new Event('change'));
    }
  }

  get dateRangeLabel() {
    return this.toggleTarget.querySelector('#range-label');
  }

  set displayText(value) {
    if (!this.hasToggleTarget) return;
    if (value === null || value === '' || value === undefined) {
      value = 'Gesamter Zeitraum';
    }

    this.dateRangeLabel.innerText = value;
  }

  showPickr() {
    this.pickr.open();
    return false;
  }
}
