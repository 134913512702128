import { Controller } from "@hotwired/stimulus"
import bootstrap from 'bootstrap'

// Connects to data-controller="transfer"
export default class extends Controller {
  static targets = ["bookingDate"]

  confirmBooking() {
    let bookingDate = this.bookingDateTarget.value;

    $.ajax({
      method: 'POST',
      url: `/admin/payout/confirm_bookings`,
      contentType: "application/json",
      data: JSON.stringify({ confirm: { booking_date: bookingDate, ids: this.selectedRowIds() }})
    })
    .done( () => {})
    .fail( () => console.log('failed', this.selectedRowIds()) )
    .always( () => {
      this.getTransferTable().ajax.reload( null, false )
      this.hideModal('#confirm-modal');
    } );
  }

  hideModal(modal) {
    let confirmModal = bootstrap.Modal.getOrCreateInstance(modal);
    confirmModal.hide();
    $('.modal-backdrop').remove();
  }

  selectedRowIds() {
    return this.getTransferTable().rows( { selected: true } ).ids().toArray();
  }

  getTransferTable() {
    return $('#transfers-table').DataTable();
  }
}
