import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ocr-create-rules"
export default class extends Controller {
  static targets = [
    "ruleTypeSelect", "ruleTextInputs", "ruleOldValueInput", "ruleSelectInputs", "ruleNewFieldSelect",
    "ruleFieldSelect", "selectedOcrFieldValueNew", "selectedOcrFieldValue", "ocrResponse"
  ]
  connect() {
    this.parsedOcrResponse = JSON.parse(this.ocrResponseTarget.innerHTML)
    console.dir(this.parsedOcrResponse)

    // toggle visibility of rule inputs
    this.ruleTypeSelectTarget.addEventListener('change', (event) => {
      if (event.target.value === 'replaceResults') {
        this.ruleTextInputsTarget.classList.add('d-none')
        this.ruleSelectInputsTarget.classList.remove('d-none')
      } else {
        this.ruleTextInputsTarget.classList.remove('d-none')
        this.ruleSelectInputsTarget.classList.add('d-none')
      }

      if (event.target.value === 'replaceString') {
        this.ruleOldValueInputTarget.disabled = false
      } else {
        this.ruleOldValueInputTarget.disabled = true
      }
    })
  }

  showCurrentValue(event) {
    this.selectedOcrFieldValueTarget.innerHTML = this.extractFieldValue(event.target.value)
  }

  showNewValue(event) {
    this.selectedOcrFieldValueNewTarget.innerHTML = this.extractFieldValue(event.target.value)
  }

  extractFieldValue(concatenatedKey) {
    let [category, field] = concatenatedKey.split('/')
    let value;

    if (this.parsedOcrResponse.response[category] && this.parsedOcrResponse.response[category][field]) {
      value = this.parsedOcrResponse.response[category][field]
    } else if (this.parsedOcrResponse.response[field]) {
      value = this.parsedOcrResponse.response[field]
    } else {
      value = '-- kein Wert --'
    }

    if (Array.isArray(value)) {
      value = value.map((v, i) => `<p>${i + 1}) ${v}</p>`)
    }

    return value
  }
}
