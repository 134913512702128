import { Controller } from "@hotwired/stimulus"
import { useMutation } from "stimulus-use";

// Connects to data-controller="prevent-input-submit"
export default class extends Controller {
  static values = {
    initiallyHidden: { type: Boolean, default: true }
  }
  connect() {
    console.log('initially hidden: ' + this.initiallyHiddenValue)
    this.toggleDisabledOnFields(this.element.querySelectorAll('input'), this.initiallyHiddenValue);
    useMutation(this, { attributes: true })
  }

  mutate(mutations) {
    for (const mutation of mutations) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        if (mutation.target.classList.contains('show')) {
          this.toggleDisabledOnFields(mutation.target.querySelectorAll('input'), false);
          this.toggleDisabledOnFields(mutation.target.querySelectorAll('select'), false);
        } else {
          this.toggleDisabledOnFields(mutation.target.querySelectorAll('input'), true);
          this.toggleDisabledOnFields(mutation.target.querySelectorAll('select'), true);
        }
      }
    }
  }

  // Toggle inputs disabled state based on the selected tab
  toggleDisabledOnFields(inputs, disabled) {
    if (!inputs) return;

    inputs.forEach(input => {
      input.disabled = disabled;
    });
  }
}
