import {Controller} from "@hotwired/stimulus"

// TODO: this should be refactored using TurboStreams!
// Connects to data-controller="progress-bar"
export default class extends Controller {
  static values = {
    pollUrl: String,
    pollInterval: {Number, default: 500},
    startUrl: String,
    progress: Number
  }

  connect() {
    if (!this.hasPollUrlValue) return;
    if (!this.element.dataset.progressValue) return;

    if (this.element.dataset.progressValue < 100) {
      setTimeout(() => {
        fetch(this.pollUrlValue)
          .then((response) => response.text())
          .then((html) => {
            Turbo.renderStreamMessage(html)
          })
          .catch(error => {
            console.error(error)
          });
      }, parseInt(this.pollIntervalValue))
    } else {
      // Reset the value before reloading the page
      this.element.dataset.progressValue = 0;
      location.reload();
    }
  }

  collectSelectedClearings(event) {
    const clearings = this.collectClearings();
    const clearingType = event.target.dataset.clearingType;
    this.sendClearingsToServer(event.target.dataset.startUrlValue, {[clearingType]: clearings});
  }

  collectClearings() {
    let clearings = {};
    let selectedRows = document.querySelectorAll('tr.selected');
    selectedRows.forEach((row) => {
      clearings[row.id] = this.parseBalance(row);
    });
    return clearings;
  }

  parseBalance(row) {
    return Number(row.querySelector('td.balance').innerText.replace(/[^0-9\,-]+/g, "").replace(',', '.'));
  }

  sendClearingsToServer(url, clearings) {
    fetch(url, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(clearings)
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html)
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  getHeaders() {
    return {
      'Content-Type': 'application/json',
      "Accept": "text/vnd.turbo-stream.html",
      "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
    };
  }
}
