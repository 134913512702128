import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="order-filter"
export default class extends Controller {
  static targets = [ 'stateCheckBox', 'resetStateCheckBoxes', 'dueCheckBox', 'resetDueCheckBoxes', 'factoringTypeCheckBoxes', 'resetFactoringTypeCheckBoxes' ]
  connect() {
    if (this.hasResetStateCheckBoxesTarget) {
      this.resetStateCheckBoxesTarget.addEventListener('click', (_event) => {
        this.resetStateCheckBoxes()
      })
    }

    if (this.hasResetDueCheckBoxesTarget) {
      this.resetDueCheckBoxesTarget.addEventListener('click', (_event) => {
        this.resetDueCheckBoxes()
      })
    }

    if (this.hasResetFactoringTypeCheckBoxesTarget) {
      this.resetFactoringTypeCheckBoxesTarget.addEventListener('click', (_event) => {
        this.resetFactoringTypeCheckBoxes()
      })
    }
  }

  resetStateCheckBoxes() {
    this.stateCheckBoxTargets.forEach((element) => {
      element.checked = false
    })
  }

  resetDueCheckBoxes() {
    this.dueCheckBoxTargets.forEach((element) => {

      element.checked = false
    })
  }

  resetFactoringTypeCheckBoxes() {
    this.factoringTypeCheckBoxesTargets.forEach((element) => {

      element.checked = false
    })
  }
}
