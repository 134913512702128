import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="decimal-point-enforcement"
export default class extends Controller {
  connect() {
    this.element.addEventListener("keydown", this.keydownHandler)
  }

  // enforce decimal point instead of comma
  keydownHandler(event) {
    if (event.key === ",") {
      event.preventDefault()
      event.stopPropagation()
      if (event.target.value.includes(".")) return;

      event.target.value += "."
    }
  }
}
