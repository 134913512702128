import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table"
export default class extends Controller {
  connect() {
    console.log("table controller connected!", this.element)
  }

  // Removes the closest row from the table where the event was triggered.
  // Usage: data-action="click->table#removeRow"
  removeRow(event) {
    event.preventDefault();
    event.target.closest("tr").remove();
  }
}
