import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="deflect-deposit"
export default class extends Controller {
  static targets = [ 'radio', 'reTransfer', 'forward', 'forwardToOther', 'proceedsOnDeprecation' ]
  connect() {}

  // Add eventListener to all radio buttons on connect
  radioTargetConnected(element) {
    element.addEventListener('change', this.toggleFields.bind(this, element));
  }

  // Toggle fields visibility and inputs disabled state based on the selected radio button
  toggleFields(element) {
    let notSelectedRadios = this.radioTargets.filter((radio) => radio.value !== element.value);
    notSelectedRadios.forEach((radio) => {
      // label     --> this is the label that contains the radio button
      //   radio   --> this is the radio button
      // div       --> this is the div that contains the fields
      radio.parentElement.nextElementSibling.classList.add('d-none');
      this.toggleForwardFields(radio.parentElement.nextElementSibling.querySelectorAll('input'), true);
      this.toggleForwardFields(radio.parentElement.nextElementSibling.querySelectorAll('select'), true);
    })


    element.parentElement.nextElementSibling.classList.remove('d-none');
    this.toggleForwardFields(element.parentElement.nextElementSibling.querySelectorAll('input'), false);
    this.toggleForwardFields(element.parentElement.nextElementSibling.querySelectorAll('select'), false);
  }

  // Toggle inputs disabled state based on the selected radio button
  toggleForwardFields(inputs, disabled) {
    if (!inputs) return;

    inputs.forEach(input => {
      input.disabled = disabled;
    });
  }
}
