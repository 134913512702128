import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="business-account"
export default class extends Controller {
  static targets = [ 'accountKindSelect' , 'accountNumberField', 'bankNamePayin', 'customerNamePayin', 'bankNameSelect',
                            'accountNameField', 'ibanField', 'bankName', 'bicField' ,'submitButton']
  static values = {
    payinMinAccountNumber: Number,
    payinMaxAccountNumber: Number,
    payinNewAccountNumber: Number,
    payoutMinAccountNumber: Number,
    payoutMaxAccountNumber: Number,
    payoutNewAccountNumber: Number,
  }
  connect() {
    this.accountKindSelectTarget.addEventListener('change', this.accountKindChanged.bind(this))
    if (!this.ibanFieldTarget) return

    this.ibanFieldTarget.addEventListener('change', this.ibanChanged.bind(this))
  }

  accountKindChanged(event) {
    const accountKind = event.currentTarget.value
    // 'payin_bank_account' is from AifinyoBankAccount.kinds
    if (accountKind === 'payin_bank_account') {
      this.accountNumberFieldTarget.min = this.payinMinAccountNumberValue
      this.accountNumberFieldTarget.max = this.payinMaxAccountNumberValue
      this.accountNumberFieldTarget.value = this.payinNewAccountNumberValue

      // visibility of inputs
      this.bankNamePayinTarget.classList.remove('d-none')
      this.bankNameSelectTarget.disabled = false

      this.customerNamePayinTarget.classList.remove('d-none')
      this.accountNameFieldTarget.disabled = false
      this.accountNameFieldTarget.required = true
    } else {
      this.accountNumberFieldTarget.min = this.payoutMinAccountNumberValue
      this.accountNumberFieldTarget.max = this.payoutMaxAccountNumberValue
      this.accountNumberFieldTarget.value = this.payoutNewAccountNumberValue

      // visibility of inputs
      this.bankNamePayinTarget.classList.add('d-none')
      this.bankNameSelectTarget.disabled = true

      this.customerNamePayinTarget.classList.add('d-none')
      this.accountNameFieldTarget.disabled = true
      this.accountNameFieldTarget.required = false
    }
  }

  ibanChanged(event) {
    let iban = event.target.value.trim()
    if (iban) {
      get(`/admin/settings/infact_bank_accounts/bank_account_name?iban=${iban}`, { responseKind: 'json' })
        .then(response => response.json.then(data => {
          if (data.bank_name.trim() === '') {
            this.bankNameTarget.innerText = 'IBAN invalide'
            this.bankNameTarget.classList.add('text-danger')
            this.bicFieldTarget.value = ''
            this.submitButtonTarget.disabled = true
          } else {
            this.bankNameTarget.classList.remove('text-danger')
            this.bankNameTarget.innerText = data.bank_name
            this.bicFieldTarget.value = data.bic
            this.submitButtonTarget.disabled = false
          }
        }))
    }
  }
}
