import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="prevent-submit-on-enter"
export default class extends Controller {
  connect() {
    this.element.addEventListener("keydown", this.preventSubmitOnEnter)
  }

  preventSubmitOnEnter(event) {
    if (event.key === "Enter") {
      event.preventDefault()
      return false
    }
  }
}
