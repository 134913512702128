import {Controller} from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="tom-select"

export default class extends Controller {

  static values = {
    remoteUrl: String,
    remoteQueryField: String,
    remoteValueField: String,
    remoteLabelField: String,
    placeholder: String,
    appendQuery: Boolean,
    callback: String,
    useClearButton: { type: Boolean, default: false }
  }

  static afterLoad(identifier, application) {
    // use the application instance to read the configured 'data-controller' attribute
    const { controllerAttribute } = application.schema

    // update any legacy buttons with the controller's registered identifier
    const updateLegacySelects = () => {
      const legacySelector = 'select';
      document.querySelectorAll(legacySelector).forEach((element) => {
        // skip legacy initialization if element has the "no-tom" class
        // sometimes you just don't want to use tom-select
        if ( ! element.classList.contains('no-tom') ) {
          // assigns the "data-controller" attribute with "tom-select" to the element
          // this will trigger the controller to be instantiated and connected
          element.setAttribute(controllerAttribute, identifier);
        }
      });
    }

    // called as soon as registered so DOM may not have loaded yet
    if (document.readyState === "loading") {
      document.addEventListener("turbo:load", updateLegacySelects)
    } else {
      updateLegacySelects()
    }
  }

  connect() {
    let settings = {
      allowEmptyOption: true,
      ...this.pluginSettings(),
      ... this.callbacks(),
      ...this.remoteSettings(),
      ...this.placeholderSettings()
    };
    new TomSelect(this.element, settings);
    // make placeholder visible
    this.element.tomselect.inputState();

    // This is a workaround to clear the input when the user clicks on the input
    this.element.tomselect.on('focus', () => {
      this.element.tomselect.clear();
    })

    // This is a workaround to add the selected item when the user clicks outside the input
    this.element.tomselect.on('blur', () => {
      if (!this.element.tomselect.activeOption) return;
      this.element.tomselect.addItem(this.element.tomselect.activeOption.dataset.value);
    })

    // Remove the focus from the input when an item is added
    // Workaround to prevent content shifting when the input is unfocused while clicking on save button
    // resulting in having to click twice on the save button
    this.element.tomselect.on('item_add', () => {
      this.element.tomselect.blur();
    })

    // When an item is selected we want to hide the placeholder
    // When the item is removed we want to show the placeholder
    // Although the placeholder should hide on selecting an item, it doesn't
    // See: https://tom-select.js.org/docs/ => hidePlaceholder
    this.element.tomselect.on('change', (item) => {
      if (!item) {
        this.element.tomselect.settings.hidePlaceholder = false;
        this.element.tomselect.inputState();
      }else{
        this.element.tomselect.settings.hidePlaceholder = true;
        this.element.tomselect.inputState();
      }
    })

    // Remove the focus from the input when an item is added
    // Workaround to prevent content shifting when the input is unfocused while clicking on save button
    // resulting in having to click twice on the save button
    this.element.tomselect.on('item_add', () => {
      this.element.tomselect.blur();
    })
  }

    pluginSettings() {
       if (!this.hasUseClearButtonValue) return {};
         return {
            plugins: ['clear_button']
       };
    }

  remoteSettings() {
    if (!this.hasRemoteUrlValue) return {};

    return {
      searchField: this.remoteLabelFieldValue, // this is not a typo, without the second label the results are not shown
      load: this.loadRemoteData.bind(this)
    };
  }

  placeholderSettings() {
    if (!this.hasPlaceholderValue) return {};

    return {
      placeholder: this.placeholderValue,
      hidePlaceholder: false
    };
  }

  callbacks() {
    if(!this.callbackValue) return {};

    return {
      onChange: window[this.callbackValue]
    }
  }

  loadRemoteData(query, callback) {
    let remoteUrl = `${this.remoteUrlValue}${this.appendQueryValue ? '&' : '?'}${this.remoteQueryFieldValue}=${encodeURIComponent(query)}`

    fetch(remoteUrl)
      .then(response => response.json())
      .then(data => {
        // remap the data to the format tom-select expects
        data = data.map(item => {
          return {
            value: item[this.remoteValueFieldValue],
            text: item[this.remoteLabelFieldValue]
          };
        });
        callback(data);
      })
      .catch(error => {
        console.error(error);
        callback();
      });
  }

  disconnect() {
    this.element.tomselect.destroy();
  }
}
