import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="redirect"
// Allows to transform a TurboStream request into a redirect
export default class extends Controller {
  static values = { url: String, openUrlInNewTab: { type: String, default: '' } }
  connect () {
    if (this.openUrlInNewTabValue !== '') {
      window.open(this.openUrlInNewTabValue, '_blank')
    }
    window.location.href = this.urlValue
  }
}
