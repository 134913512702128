import { Controller } from "@hotwired/stimulus"
import bootstrap from 'bootstrap'

// Connects to data-controller="bs-modal"
// Allows you to open a Bootstrap modal from Stimulus e.g. when responding to a turbo_stream where JavaScript is not executed
// see app/views/admin/bank_transactions/change_area_modal.haml for an example
export default class extends Controller {
  static values = {
    keepOpen: { type: Boolean, default: false }
  }
  connect() {
    this.modal = new bootstrap.Modal(this.element)
    this.form = this.element.querySelector('form')

    if (this.form && !this.keepOpenValue) {
      this.form.addEventListener('submit', () => {
        this.modal.hide()
      })
    }

    this.modal.show()
  }

  close() {
    this.modal.hide()
  }
}
