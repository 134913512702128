import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-format"
export default class extends Controller {

  static targets = ['source', 'label'];
  static values = { 'locale': String }

  connect() {
    this.localeConfigs = {
      'de': {
        'value': 'de-DE',
        'currency': 'EUR',
        'style': 'currency'
      },
      'en': {
        'value': 'en-US',
        'currency': 'USD',
        'style': 'currency'
      }
    };

    this.updateLabel();
  }

  get localeConfig() {
    return (this.hasLocaleValue && this.localeConfigs[this.localeValue])
      ? this.localeConfigs[this.localeValue]
      : this.localeConfigs['en'];
  }

  get source() {
    if (this.hasSourceTarget) {
      return parseFloat(this.sourceTarget.value)
    }
    return null;
  }

  get currencyFormatter() {
    let { value, currency, style } = this.localeConfig;
    return new Intl.NumberFormat(value, { style, currency });
  }

  handleChange() {
    this.updateLabel();
  }

  updateLabel() {
    if (isNaN(this.source))
      return;

    if (this.hasLabelTarget) {
      this.labelTarget.textContent = this.currencyFormatter.format(this.source);
    }
  }
}
