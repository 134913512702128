import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = [ "hide" ]
  static classes = [ "toggle" ]

  update() {
    const links = this.hideTargets;
    links.map((item) => {
      item.classList.toggle(this.toggleClass);
    });
  }
}
