import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="accountancy-amount"
export default class extends Controller {
  static targets = [ 'select', 'amount', 'venBooking', 'hint' ]
  static values = { externalId: String }
  connect() {
    console.log(`this.hasSelectTarget: ${this.hasSelectTarget}`)
    console.log(`this.hasAmountTarget: ${this.hasAmountTarget}`)
    console.log(`this.hasvenBookingTarget: ${this.hasvenBookingTarget}`)
    console.log(`this.hasHintTarget: ${this.hasHintTarget}`)
    console.log(`this.externalIdValue: ${this.externalIdValue}`)

    if (!this.hasSelectTarget) return
    if (!this.hasAmountTarget) return
    if (!this.hasVenBookingTarget) return
    if (!this.hasHintTarget) return
    if (!this.externalIdValue) return

    this.selectTarget.addEventListener('change', (event) => {
      this.getAmount(event)
    })
  }

  getAmount(event) {
    get(`/admin/business/accountancy/${this.externalIdValue}/amount_by_order_booking_type?order_booking_type=${event.target.value}`, {
      responseKind: 'json'
    }).then(response => response.json.then(data => {
      console.log(data)
      this.amountTarget.value = data.amount

      if (data.show_ven_booking) {
        this.venBookingTarget.classList.remove('d-none')
      } else {
        this.venBookingTarget.classList.add('d-none')
      }

      if (data.show_hint) {
        this.hintTarget.classList.remove('d-none')
      } else {
        this.hintTarget.classList.add('d-none')
      }
    }).then(error => {
      console.log(error)
    }))
  }
}
