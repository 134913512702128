import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bank-transaction-deposit"
export default class extends Controller {
  static targets = [ 'depositList', 'orderList', 'depositEntry', 'orderEntry',
                              'submit', 'amount', 'noOrdersSelected', 'orderAmountSum', 'summedUpOrderAmounts', 'orderAmountTooHighWarning' ]

  connect() {
    this.Euro = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    });

    this.orderAmount = 0.0
    this.depositAmount = 0.0

    this.updateSubmitButton()
  }

  depositEntryTargetConnected(element) {
    // because the deposit is inserted into the DOM as a copy we do not need to sum up the amounts
    // since this is a radio button we can just set the amount due to the fact that only one can be selected
    if (element.checked) {
      this.depositAmount = parseFloat(element.dataset.amount)
      this.updateOrderAmountSum()
    }
  }

  orderEntryTargetConnected(element) {
    // this is a checkbox and there can be multiple selected
    // because the order is inserted into the DOM as a copy on selection we need to sum up the amounts of the checked orders
    if (element.checked) {
      this.orderAmount += parseFloat(element.dataset.amount)

      // update the sum of selected orders
      this.orderListTarget.dataset.orderAmount = this.orderAmount
      this.updateOrderAmountSum()
    }
  }

  orderEntryTargetDisconnected(element) {
    // correct order amount or reset it
    if (this.orderAmount > 0.0) {
      this.orderAmount -= parseFloat(element.dataset.amount)
    } else {
      this.orderAmount = 0.0
    }
    this.updateOrderAmountSum()
  }

  updateOrderAmountSum() {
    console.log(`orderAmount: ${this.orderAmount}, depositAmount: ${this.depositAmount}`)
    if (this.orderAmount === 0.0) {
      console.log('orderAmount is 0.0')
      this.noOrdersSelectedTarget.classList.remove('d-none');
      this.orderAmountTooHighWarningTarget.classList.add('d-none');
      this.summedUpOrderAmountsTarget.classList.add('d-none');
    } else if (this.orderAmount > this.depositAmount) {
      console.log('orderAmount is too high')
      this.noOrdersSelectedTarget.classList.add('d-none');
      this.summedUpOrderAmountsTarget.classList.remove('d-none');
      this.summedUpOrderAmountsTarget.classList.add('text-danger');
      this.orderAmountTooHighWarningTarget.classList.remove('d-none');
    } else {
      console.log('orderAmount is not too high nor zero')
      this.noOrdersSelectedTarget.classList.add('d-none');
      this.summedUpOrderAmountsTarget.classList.remove('d-none');
    }
    this.orderAmountSumTarget.innerText = this.Euro.format(this.orderAmount)
  }

  updateSubmitButton() {
    // not quite sure what is represented by accounting_transaction_amount - taken from inline js
    let accounting_transaction_amount = Math.min(this.orderAmount, this.depositAmount)
    let selectedOrdersCount = this.orderListTarget.querySelectorAll('input[type="checkbox"]:checked').length
    let selectedDepositsCount = this.depositListTarget.querySelectorAll('input[type="radio"]:checked').length

    // disable submit button if no orders or no deposits are selected
    if (selectedOrdersCount === 0 || selectedDepositsCount === 0) {
      this.submitTarget.disabled = true
      this.submitTarget.parentElement.classList.add('cursor-not-allowed');
      return
    } else {
      this.submitTarget.disabled = false
      this.submitTarget.parentElement.classList.remove('cursor-not-allowed');
    }

    // update the submit button color
    // TODO: get explanation for the logic behind the colors
    if (accounting_transaction_amount === this.depositAmount && accounting_transaction_amount === this.orderAmount) {
      this.submitTarget.classList.remove('btn-danger');
      this.submitTarget.classList.remove('btn-warning');
      this.submitTarget.classList.add('btn-primary');
    } else if (accounting_transaction_amount === this.depositAmount || accounting_transaction_amount === this.orderAmount) {
      this.submitTarget.classList.remove('btn-primary');
      this.submitTarget.classList.remove('btn-danger');
      this.submitTarget.classList.add('btn-warning');
    } else {
      this.submitTarget.classList.remove('btn-primary');
      this.submitTarget.classList.remove('btn-warning');
      this.submitTarget.classList.add('btn-danger');
    }
  }
}
