import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="document-selection"
// handle the selection of a document with dependent kind select update
export default class extends Controller {
  static targets = ['baseSelect', 'subSelect', 'dateInfo']
  static values = {
    kindUrl: String,
    dateInfo: String
  }
  connect() {
    if(!this.baseSelectTarget) return;
    if(!this.subSelectTarget) return;
    if(!this.hasKindUrlValue) return;

    this.baseSelectTarget.addEventListener('change', (event) => {
      this.getKinds(event.target.value);
    })

    if (this.hasDateInfoTarget && this.hasDateInfoValue) {
      this.subSelectTarget.addEventListener('change', (event) => {
        console.log(event.target.value)
        console.log(this.subSelectTarget.value)
        if(event.target.value === 'cancellation_confirmation_factorer'){
          this.dateInfoTarget.innerText = this.dateInfoValue
        } else {
          this.dateInfoTarget.innerText = ''
        }
      })
    }
  }

  getKinds(mainCategory) {
    fetch(this.kindUrlValue + mainCategory)
      .then(response => response.json())
      .then(data => {
        this.subSelectTarget.innerHTML = '';
        for (const key in data) {
          this.subSelectTarget.appendChild(new Option(key, data[key], false, false));
        }
      });
  }
}
