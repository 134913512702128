import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="numbers-hint"
export default class extends Controller {

  static targets = [ "input", "hint" ]

  connect() {
  }

  updateHint() {
    let val = this.amountCheck(this.inputTarget)

    this.hintTarget.innerHTML = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(val);
  }

  amountCheck(object) {
    if(object.hasAttribute('max') && object.max != "") {
      if (parseFloat(object.value) > object.max) {
        return parseFloat(object.max)
      }
    }

    if(object.hasAttribute('min')) {
      if (parseFloat(object.value) < object.min) {
        return parseFloat(object.min)
      }
    }

    return parseFloat(object.value)
  }
}
