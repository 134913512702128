import {Controller} from "@hotwired/stimulus"
import Popover from "bootstrap/js/dist/popover"

// https://getbootstrap.com/docs/5.2/components/popovers/
// Connects to data-controller="popover"
export default class extends Controller {
  connect() {
    // allow custom elements in popover
    // further info: https://getbootstrap.com/docs/5.3/getting-started/javascript/#sanitizer
    const myDefaultAllowList = Popover.Default.allowList

    // To allow table elements
    myDefaultAllowList.table = []
    // To allow thead elements
    myDefaultAllowList.thead = []
    // To allow tbody elements
    myDefaultAllowList.tbody = []
    // To allow tr elements
    myDefaultAllowList.tr = []
    // To allow th elements
    myDefaultAllowList.th = []
    // To allow td elements
    myDefaultAllowList.td = []
    this.popover = new Popover(this.element, {
      sanitize: true,
      html: true,
      allowList: myDefaultAllowList
    })
  }
}
