import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // see: https://stimulus.hotwired.dev/reference/values
  static values = {
    persistent: { type: Boolean, default: false },
    hideAfter: { type: Number, default: 5_000 },
  }
  connect() {
    this.closeButton = this.element.querySelector('.btn-close')
    // start css animation of sliding in the flash message
    this.element.classList.add('flash-message-component--show')

    // do not auto hide a flash message if it is a persistent message
    if (this.persistentValue) return

    // hide the flash message after the configured number of milliseconds
    setTimeout(
      () => {
        this.closeButton.click()
      },
      parseInt(this.hideAfterValue))
  }
}
