import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mt940-import"
export default class extends Controller {
  static targets = [ 'filesField', 'uploadBtn' ]

  connect() {
    // show file select dialog of OS
    this.uploadBtnTarget.addEventListener('click', () => {
      this.filesFieldTarget.click()
    });

    this.filesFieldTarget.addEventListener('change', () => {
      // when there are files selected, submit the form
      if (this.filesFieldTarget.files.length) {
        this.element.submit();
      }
    });
  }
}
